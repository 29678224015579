export const AUTH_STATE = 'auth';
export const ROOT_STATE = 'root';
export const ROUTER_STATE = 'router_c';
export const CURRENT_USER_STATE = 'currentUser';
export const ORG_STATE = 'org';
export const PRODUCTS_STATE = 'products';
export const STORAGE_STATE = 'storage';
export const ADMIN_USER_STATE = 'admin_user';
export const ADMIN_ORG_STATE = 'admin_org';
export const LOCAL_DATASETS_STATE = 'datasets';
export const SPECIAL_ANALYSIS_STATE = 'special_analysis';
export const SETTING_STATE = 'setting';
export const NEWS_STATE = 'news';
export const NEWS_CATEGORIES_STATE = 'news_categories';
export const DATA_UPDATES_STATE = 'data_updates';
