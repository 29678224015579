import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ProductsState } from '../../state/products/products.state';
import { Product } from '../../shared/graphql/generated/graphql';
import { SearchService } from 'src/app/shared/services/search.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit, OnDestroy {
  selectedProductSubscription!: Subscription;

  selectedProduct$: Observable<Product | undefined> = this.store.select(ProductsState.selectedProduct);

  constructor(public searchService: SearchService, private store: Store) {}

  getSelectedProduct() {
    return this.store.selectSnapshot(ProductsState.selectedProduct);
  }

  get selectedProductTitle() {
    return this.getSelectedProduct()?.name;
  }

  ngOnInit(): void {
    this.selectedProductSubscription = this.selectedProduct$.subscribe(
      (value) => {
        this.searchService.onSearch(this.searchService.searchText);
      }
    );
  }

  ngOnDestroy(): void {
    this.selectedProductSubscription?.unsubscribe();
  }
}
