import { Component, inject } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { UserSelf } from "../shared/graphql/generated/graphql";
import { Observable } from "rxjs";
import { Store } from "@ngxs/store";
import { CurrentUserState } from "../state/current_user/current_user.state";
import { AuthState } from "../state/auth/auth.state";
import { Auth } from "../state/auth/auth.action";
import { Router } from '@angular/router';
import { DestroyStreamComponent } from '../shared/components/destroy-stream/destroy-stream.component';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './app.profilesidebar.component.html'
})
export class AppProfileSidebarComponent extends DestroyStreamComponent {

  profile$: Observable<UserSelf | undefined> = this.store.select(CurrentUserState.profile);
  isUserOrgAdmin$: Observable<boolean> = this.store.select(AuthState.isUserOrgAdmin);

  constructor(public layoutService: LayoutService, private store: Store, private router: Router) {
    super()
  }

  get visible(): boolean {
    return this.layoutService.state.profileSidebarVisible;
  }

  set visible(_val: boolean) {
    this.layoutService.state.profileSidebarVisible = _val;
  }

  onMenuItemClick(callback?: Function) {
    if (callback) callback();
  }

  onSignOutClick() {
    this.store.dispatch(new Auth.Logout());
      // .pipe(takeUntil(this.destroy$)).subscribe(() => {
      //   /**
      //    * The redirection to auth/login should be done here instead of in the AuthState.logout, or
      //    * otherwise it will lead to the following strange behaviors:
      //    * 1. cause a warning in the console: 'Navigation triggered outside Angular zone, did you forget to call 'ngZone.run()'?'
      //    * 2. make angular appending the 'app-layout' alongside the 'blank-layout' to the dom of the login component which then
      //    * 3. result in the black screen problem at login page after the logout
      //    */
      //   this.router.navigate(['auth', 'login']);
      // });
  }
}
