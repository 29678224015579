import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AuthState } from '../state/auth/auth.state';
import { Observable } from 'rxjs';
import { CurrentUserState } from '../state/current_user/current_user.state';
import { Product, UserSelf } from '../shared/graphql/generated/graphql';
import { animate, style, transition, trigger } from '@angular/animations';
import { ProductsState } from '../state/products/products.state';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1s ease-out', style({ height: 300, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppTopbarComponent implements OnInit {
  @ViewChild('menubutton') menuButton!: ElementRef;

  isUserAdmin$: Observable<boolean> = this.store.select(AuthState.isUserAdmin);
  profile$: Observable<UserSelf | undefined> = this.store.select(CurrentUserState.profile);
  selectedProduct$: Observable<Product | undefined> = this.store.select(ProductsState.selectedProduct);
  fetching$: Observable<Boolean | undefined> = this.store.select(ProductsState.fetching);

  constructor(
    public layoutService: LayoutService,
    private store: Store,
    private router: Router
  ) {}

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showProfileSidebar();
  }

  onAdminClick() {
    this.router.navigate(['/administration/users']);
  }

  get hideSearchbar() {
    const inHomePage =
      this.router.url === '/' || this.router.url.slice(1) === '?';
    const inSearchPage = this.router.url.includes('/search');

    return inHomePage || inSearchPage;
  }
  ngOnInit(): void {
  }
}
