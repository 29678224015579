import { Injectable } from "@angular/core";
import { CreateDataUpdateInput, DataUpdate, DataUpdateBulkCreateGQL, DataUpdatesGQL } from "../graphql/generated/graphql";
import { map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataUpdateService {
  constructor(
    private dataUpdatesGQL: DataUpdatesGQL,
    private dataUpdateBulkCreateGQL: DataUpdateBulkCreateGQL
  ) {}

  getDataUpdates() {
    return this.dataUpdatesGQL
      .fetch()
      .pipe(map((response) => response.data.dataUpdates as DataUpdate[]));
  }

  createDataUpdates(createDataUpdateInputs: CreateDataUpdateInput[]) {
    return this.dataUpdateBulkCreateGQL
      .mutate({ createDataUpdateInputs })
      .pipe(map((result) => result.data?.dataUpdateBulkCreate as DataUpdate[]));
  }
}