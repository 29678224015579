import { Routes } from '@angular/router';
import { AppLayoutComponent } from '../layout/app.layout.component';
import { AuthGuardService } from '../shared/guards/auth-guard.service';
import { ClaimGuardService } from '../shared/guards/claim-guard.service';
import { AuthComponent } from '../auth/auth.component';
import {AsyncInitializationGuardService} from "../shared/guards/async-initialization-guard.service";

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuardService, ClaimGuardService, AsyncInitializationGuardService],
    canActivateChild: [ClaimGuardService],
    children: [
      {
        path: '',
        loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'dashboards',
        loadChildren: () => import('../dashboards/dashboards.module').then((m) => m.DashboardsModule),
        data: {
          preload: false
        }
      },
      {
        path: 'user',
        loadChildren: () => import('../user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'org',
        loadChildren: () => import('../org/org.module').then((m) => m.OrgModule),
        data: {
          requiredClaims: ['administer_own_organisation'],
        },
      },
      {
        path: 'settings',
        loadChildren: () => import('../settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'help',
        loadChildren: () => import('../help-pages/help-pages.module').then((m) => m.HelpPagesModule),
      },
      {
        path: 'search',
        loadChildren: () => import('../search-result/search-result.module').then((m) => m.SearchResultModule),
      },
    ],
  },
  {
    path: '',
    component: AuthComponent,
    loadChildren: () => import('../auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'administration',
    loadChildren: () => import('../administration/administration.module').then((m) => m.AdministrationModule),
    canActivate: [AuthGuardService, ClaimGuardService],
    data: {
      requiredClaims: ['admin_access'],
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];
