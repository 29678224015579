import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DataUpdatesStateModel } from './data-updates.model';
import { DATA_UPDATES_STATE } from '..';
import { Injectable } from '@angular/core';
import { DataUpdates } from './data-updates.action';
import { tap } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { DataUpdateService } from 'src/app/shared/services/data-update.service';

@State<DataUpdatesStateModel>({
  name: DATA_UPDATES_STATE,
  defaults: {
    dataUpdates: [],
    fetching: false,
  },
})
@Injectable()
export class DataUpdatesState {
  constructor(
    private router: Router,
    private dataUpdatesService: DataUpdateService,
    private messageService: MessageService,
  ) {}

  @Selector()
  static dataUpdates(state: DataUpdatesStateModel) {
    return state.dataUpdates;
  }

  @Selector()
  static dataUpdatesByProductId(state: DataUpdatesStateModel) {
    return (productId: number | null) => state.dataUpdates.filter((dataUpdate) => dataUpdate.productId === productId)
  }

  @Selector()
  static fetching(state: DataUpdatesStateModel) {
    return state.fetching;
  }

  @Action(DataUpdates.GetDataUpdates)
  loadDataUpdates({ patchState }: StateContext<DataUpdatesStateModel>) {
    patchState({ fetching: true });
    return this.dataUpdatesService.getDataUpdates().pipe(
      tap({
        next: (dataUpdates) => {
          patchState({
            fetching: false,
            dataUpdates,
          });
        },
        error: (error) => {
          patchState({
            fetching: false,
          });
        }
      })
    );
  }

  @Action(DataUpdates.CreateDataUpdates)
  createDataUpdates(ctx: StateContext<DataUpdatesStateModel>, action: DataUpdates.CreateDataUpdates) {
    return this.dataUpdatesService.createDataUpdates(action.createDataUpdatesInput).pipe(
      tap({
        next: (newDataUpdate) => {
          ctx.patchState({
            dataUpdates: [...newDataUpdate],
          })

          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Data Updates uploaded successfully.'
          })
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Data Updates upload failed.'
          })
        }
      })
    )
  }
}
