import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from './environments/environment';
import { RootComponent } from './app/root/root.component';
import { rootConfig } from './app/root/root.config';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(RootComponent, rootConfig)
  .catch((err) => console.error(err));