import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {generalMenuItemsBottom, generalMenuItemsTop, MenuItem,} from "./app.menu.items";
import {Product} from "../shared/graphql/generated/graphql";
import {map, Observable} from "rxjs";
import {Store} from "@ngxs/store";
import {ProductsState} from "../state/products/products.state";

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

  // model: MenuItem[] = [];
  accessibleProducts$: Observable<Product[] | undefined> = this.store.select(ProductsState.accessibleProducts);
  productsLoading$: Observable<boolean> = this.store.select(ProductsState.productsLoading);
  selectedProduct$: Observable<Product | undefined> = this.store.select(ProductsState.selectedProduct);
  selectedProductMenuModel$: Observable<MenuItem[]> = this.store.select(ProductsState.selectedProductMenuModel);

  menuModel$: Observable<MenuItem[]> = this.selectedProductMenuModel$.pipe(
    map((selectedProductMenuModel) => {
      return [...generalMenuItemsTop, ...selectedProductMenuModel, ...generalMenuItemsBottom];
    })
  );

  constructor(private store: Store) {

  }

  ngOnInit() {

  }
}
