import { ApplicationConfig, provideZoneChangeDetection, SecurityContext } from "@angular/core";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { provideRouter, withComponentInputBinding } from "@angular/router";
import { provideHttpClient } from "@angular/common/http";
import { withNgxsLoggerPlugin } from "@ngxs/logger-plugin";
import { withNgxsRouterPlugin } from "@ngxs/router-plugin";
import { withNgxsStoragePlugin } from "@ngxs/storage-plugin";
import { provideStore } from "@ngxs/store";
import { provideApollo } from "apollo-angular";
import { provideMarkdown } from "ngx-markdown";
import { MessageService, ConfirmationService } from "primeng/api";
import { environment } from "src/environments/environment";
import { AdminOrgState } from "../administration/state/org/admin-org.state";
import { AdminUserState } from "../administration/state/user/admin-user.state";
import { OrgState } from "../org/org-state/org.state";
import { createApollo } from "../shared/graphql/graphql.module";
import { AsyncInitializationService } from "../shared/services/async-initialization.service";
import { STORAGE_STATE } from "../state";
import { AuthState } from "../state/auth/auth.state";
import { CurrentUserState } from "../state/current_user/current_user.state";
import { DataUpdatesState } from "../state/data-updates/data-updates.state";
import { LocalDatasetsState } from "../state/local_datasets/local_datasets.state";
import { NewsState } from "../state/news/news.state";
import { NewsCategoriesState } from "../state/news_categories/news_categories.state";
import { ProductsState } from "../state/products/products.state";
import { RootState } from "../state/root/root.state";
import { SettingsState } from "../state/settings/settings.state";
import { SpecialAnalysisState } from "../state/special_analysis/special_analysis.state";
import { StorageState } from "../state/storage/storage.state";
import { routes } from "./root.routes";

export const rootConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync(),
    provideRouter(routes, withComponentInputBinding()),
    provideStore(
      [
        RootState,
        AuthState,
        CurrentUserState,
        StorageState,
        ProductsState,
        OrgState,
        AdminUserState,
        AdminOrgState,
        LocalDatasetsState,
        SpecialAnalysisState,
        SettingsState,
        NewsState,
        NewsCategoriesState,
        DataUpdatesState,
      ],
      { developmentMode: !environment.production },
      withNgxsStoragePlugin({ keys: [STORAGE_STATE] }),
      withNgxsRouterPlugin(),
      withNgxsLoggerPlugin({ disabled: environment.production }),
    ),
    provideHttpClient(),
    provideApollo(createApollo),
    provideMarkdown({
      sanitize: SecurityContext.STYLE
    }),
    MessageService,
    ConfirmationService,
    AsyncInitializationService
  ]
};