import {Injectable} from '@angular/core';
import StatesData from "src/assets/json/state.json";
import {OptionNested} from 'src/app/shared/model/OptionNestedType';
import {Option} from '../model/OptionType';
import { SearchTag } from 'src/app/dashboards/shared/interfaces/SearchTag';
import SearchTags from 'src/assets/json/search_tags.json';
import {Store} from "@ngxs/store";
import {LocalDatasetsState} from "../../state/local_datasets/local_datasets.state";
import SubMarketAbbr from "src/assets/json/sub_market_abbr.json";
import { sortAlphabeticallyPrioritizeSigma } from '../utils/sort-alphabetically-prioritize-sigma';

@Injectable({
  providedIn: 'root'
})
export class LocalDatasetsService {

  constructor(private store: Store) {
  }

  searchTags: SearchTag[] = [];
  searchTagsByProductId: {[key:string]: SearchTag[]} ={}

  _calcPrevDiffPercent(current: number, prev: number) {
    if (prev === 0) return 0;
    return ((current - prev) / prev) * 100;
  }

  getStateCapital(stateId: number) {
    return StatesData.find(d => d.id === stateId)!.capital
  }

  getStatesMap() {
    return this._createIdNameMap(StatesData)
  }

  getMethodologyMap() {
    return this._createIdNameMap(this._getMethodologyData())
  }

  getMethodologyObjectMap() {
    return this._createIdObjectMap(this._getMethodologyData())
  }

  getRegionalAssociationsMap() {
    return this._createIdNameMap(this.store.selectSnapshot(LocalDatasetsState.regionalAssociations))
  }

  getAdministrativeDistrictsMap() {
    return this._createIdNameMap(this.store.selectSnapshot(LocalDatasetsState.administrativeDistricts))
  }

  _getDistrictData() {
    return this.store.selectSnapshot(LocalDatasetsState.districts)
  }

  getDistrictsMap() {
    return this._createIdNameMap(this._getDistrictData())
  }

  getDistrictObjectMap() {
    return this._createIdObjectMap(this._getDistrictData())
  }

  getDistrictsByStateId(stateId: string) {
    const allDistricts = this._getDistrictData();

    if (stateId === '-1') {
      return allDistricts
    }

    const stateDistricts = allDistricts.filter(d => d.state_id === stateId);

    return stateDistricts
  }


  getDistrictsByRegionalAssociationIds(regionalAssociationIds: string[]) {
    return this._getDistrictData().filter(d => regionalAssociationIds.includes(d.regional_association_id))
  }

  getDistrictsByAdministrativeDistrictIds(administrativeDistrictIds: string[]) {
    return this._getDistrictData().filter(d => administrativeDistrictIds.includes(d.administrative_district_id))
  }

  getStateOptions(format?: Option) {
    return this._createOptions(StatesData, format)
  }

  getStateNames() {
    return this._createNameList(StatesData);
  }

  getStateIdCodeMap() {
    return new Map<number, string>(StatesData.map(d => [d.id, d.code]))
  }

  getStateNameCodeMap() {
    return new Map<string, string>(StatesData.map(d => [d.name, d.code]))
  }

  getStateNameIdMap() {
    return this._createNameIdMap(StatesData)
  }

  listDistrictNamesByStateName() {
    const statesMap = this.getStatesMap();

    return this._getDistrictData().reduce((acc, d) => {
      const stateName = statesMap.get(d.state_id)!;

      if (!(stateName in acc)) {
        acc[stateName] = []
      }

      acc[stateName].push(d.name)

      return acc;
    }, {} as { [k: string | number]: any[] })
  }

  listDistrictNamesByStateId() {
    return this._groupBy(this._getDistrictData(), 'state_id', (d) => d.name)
  }

  listDistrictOptionsByStateId() {
    return this._groupBy(this._getDistrictData(), 'state_id', (d) => ({label: d.name, value: d.id.toString()}))
  }

  getDistrictNames() {
    return this._createNameList(this._getDistrictData())
  }

  getDistrictNameIdMap() {
    return this._createNameIdMap(this._getDistrictData())
  }

  getDistrictOptions(format?: Option) {
    return this._createOptions(this._getDistrictData(), format).map(d => ({ ...d, state_id: d['state_id'].toString() }))
  }

  getDistrictOptionsGrouped(format?: Option) {
    return this._createOptionsNested(this._getDistrictData(), 'state', this.getStatesMap(), format)
  }

  getRegionalAssociationsOptions(format?: Option) {
    return this._createOptions(this._getRegionalAssociationsData(), format)
  }

  getRegionalAssociationsOptionsOfState(stateId: string, format?: Option) {
    return this._createOptions(this._getRegionalAssociationsData().filter(d => d.state_id === stateId), format)
  }

  _getRegionalAssociationsData() {
    return this.store.selectSnapshot(LocalDatasetsState.regionalAssociations);
  }

  getAdministrativeDistrictsOptions(format?: Option) {
    return this._createOptions(this._getAdministrativeDistrictsData(), format)
  }

  getAdministrativeDistrictsOptionsOfState(stateId: string, format?: Option) {
    return this._createOptions(this._getAdministrativeDistrictsData().filter(d => d.state_id === stateId), format)
  }

  _getAdministrativeDistrictsData() {
    return this.store.selectSnapshot(LocalDatasetsState.administrativeDistricts);
  }


  _getSubMarketData() {
    return this.store.selectSnapshot(LocalDatasetsState.subMarkets)
  }

  getSubmarketMap() {
    return this._createIdNameMap(this._getSubMarketData())
  }

  getSubmarketNameIdMap() {
    return this._createNameIdMap(this._getSubMarketData())
  }

  getSubmarketOptions(format?: Option) {
    return this._createOptions(this._getSubMarketData(), format).map(d => ({...d, methodology_id: d['methodology_id'].toString()}))
  }

  getSubmarketNames() {
    return this._createNameList(this._getSubMarketData())
  }

  listSubmarketOptionsByMethodologyId() {
    return this._groupBy(this._getSubMarketData(), 'methodology_id', (d) => ({label: d.name, value: d.id.toString()}))
  }

  getSumSubMarketNameByMethodologyId(methodologyId: number): string {
    const methodologyMap = this.getMethodologyObjectMap()
    const methodology = methodologyMap.get(methodologyId)!
    const sumSubMarketId = methodology['sum_submarket_id'];
    const sumSubMarket = this.getSubmarketMap().get(Number(sumSubMarketId))! as any;

    return sumSubMarket;
  }

  getSumSubMarketIdByMethodologyId(methodologyId: number) {
    const methodology = this.getMethodologyObjectMap().get(methodologyId)!
    return methodology['sum_submarket_id']
  }

  getSubMarketAbbrById(subMarketId: number) {
    const subMarketAbbr = SubMarketAbbr.find(d => d.id === subMarketId);

    if (!subMarketAbbr) {
      console.error("[local-datasets-service] getSubMarketAbbrById: Submarket abbreviation not found for id: " + subMarketId);
    };

    return subMarketAbbr?.abbreviation
  }

  _getIndustryData() {
    return this.store.selectSnapshot(LocalDatasetsState.industrySubMarkets);
  }

  getIndustriesOptions(format?: Option) {
    return this._createOptions(this._getIndustryData(), format).map(d => ({...d, methodology_id: d['methodology_id'].toString()}))
  }

  getIndustryIdNameMap() {
    return this._createIdNameMap(this._getIndustryData())
  }

  _getMethodologyData() {
    return this.store.selectSnapshot(LocalDatasetsState.methodologies)
  }

  getMethodologyNames() {
    return this._createNameList(this._getMethodologyData())
  }

  getMethodologyOptions() {
    return this._createOptions(this._getMethodologyData())
  }

  _getWzClassData() {
    return this.store.selectSnapshot(LocalDatasetsState.wzClasses)
  }

  getWzClassOptions(format?: Option) {
    return this._createOptions(this._getWzClassData(), format)
  }

  getWzMap() {
    return this._createIdNameMap(this._getWzClassData())
  }

  getWzIdNames() {
    return this._getWzClassData().map(({ id, name}) => ({ id, name }))
  }

  private _createIdObjectMap(data: { id: number }[]): Map<number | null, {[k:string]: any}> {
    return new Map(data.map(d => [d.id, d]))
  }

  private _createIdNameMap(data: { id: any, name: string }[]): Map<any, string> {
    return new Map(data.map(d => [d.id, d.name]))
  }

  private _createNameIdMap(data: { id: number, name: string }[]): Map<string, number> {
    return new Map(data.map(d => [d.name, d.id]))
  }

  private _createOptions(data: { id: number | string, name: string, [k: string | number]: any }[], format?: Option): {
    label: string,
    value: string,
    [k: string | number]: any
  }[] {
    if (!format) {
      return data.map(d => ({label: d.name, value: d.id.toString(), ...d})).sort((a, b) => {
        return sortAlphabeticallyPrioritizeSigma(a.label, b.label)
      })
    }

    const options = data.map(d => ({label: d[format.label], value: d[format.value!].toString(), ...d}))
    const sortedOptions = options.sort((a, b) => {
      return sortAlphabeticallyPrioritizeSigma(a.label, b.label)
    })

    return sortedOptions;
  }

  private _createOptionsNested(
    data: { id: number, name: string, [k: string]: any }[],
    groupBy: string,
    groupByRef: Map<number | null, string>,
    format?: Option
  ) {
    let grouped: { [k: number]: OptionNested } = {};

    const optionLabel = format?.label ?? 'name';
    const optionValue = format?.value ?? 'id';

    data.forEach(d => {
      if (!grouped[d[groupBy]]) {
        grouped[d[groupBy]] = {
          label: groupByRef.get(d[groupBy])!,
          value: optionValue === 'id' ? d[groupBy] : groupByRef.get(d[groupBy]),
          items: []
        }
      }

      grouped[d[groupBy]].items.push({ label: d[optionLabel], value: d[optionValue] })
    })

    return Object.values(grouped)
  }

  private _createNameList(data: { name: string }[]) {
    return data.map(d => d.name).sort((a, b) => {
      return sortAlphabeticallyPrioritizeSigma(a, b)
    })
  }

  private _includedIn(data: any[], dependency: any[], key: any) {
    return data.filter(d => dependency.includes(d[key]))
  }

  private _groupBy(data: any[], key: string, formatCallback: (d: any) => any) {
    return data.reduce((acc, d) => {
      if (!(d[key] in acc)) {
        acc[d[key]] = []
      }

      acc[d[key]].push(formatCallback(d))

      return acc;
    }, {} as { [k: string | number]: any[] })
  }

  loadSearchTags() {
    if (this.searchTags.length > 0) return;

    this.searchTags = SearchTags;
  }

  getSearchRoutesByProductId(productId: number) {
    if (this.searchTagsByProductId[productId]) {
      return this.searchTagsByProductId[productId]
    }

    const results =  this.searchTags.filter(tag => tag.product_id === productId);
    this.searchTagsByProductId[productId] = results;

    return results;
  }
}

