import { CreateDataUpdateInput } from "src/app/shared/graphql/generated/graphql";

export namespace DataUpdates {
  export class GetDataUpdates {
    static readonly type = '[DataUpdates] GetDataUpdates';
  }

  export class CreateDataUpdates {
    static readonly type = '[DataUpdates] CreateDataUpdates';
    constructor(public createDataUpdatesInput: CreateDataUpdateInput[]) { }
  }
}
