import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { AppLayoutModule } from '../layout/app.layout.module';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    ToastModule,
    ConfirmDialogModule,
    AppLayoutModule,
    ButtonModule,
    FontAwesomeModule,
    ProgressBarModule,
  ]
})
export class RootComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

  }

}
